const SectionTestimonials = () => {
    const testimonials = [
        { slug: 'jJAzUaRBq-Y' },
        { slug: 'CHyHFY8BOKk' },
        { slug: 'wP6S0uWuvyo' },
        { slug: 'S6XR05IXvOA' },
        { slug: '-j525XEVTtE' },
        { slug: 'BUyci5MM09U' },
    ];

    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold"><span className="text-warning">Testimony</span> Member</h2>
                    <p className="mt-4">Mereka telah membuktikan manfaat program ini. Kamu?</p>
                </div>
                <div className="row">
                    {testimonials.map((testimony, index) => {
                        return (
                            <div className="col-md-6 col-xl-4 p-3" key={index}>
                                <div className="ratio ratio-16x9">
                                    <iframe src={`https://www.youtube.com/embed/${testimony.slug}?rel=0`} title="YouTube video" loading="lazy" allowFullScreen />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionTestimonials;