import styles from './SectionIntro.module.scss';

const SectionIntro = () => {
    return (
        <section className="bg-dark">
            <div className="container py-5 px-4 px-lg-5">
                <div className={`card border-0 text-white ${styles.card}`}>
                    <div className="card-body p-lg-5">
                        <div className="row">
                            <div className="col-lg-6 my-auto mb-4 mb-lg-auto">
                                <img className="img-fluid" src="/assets/img/img-intro.webp" loading="lazy" alt="Intro" />
                            </div>
                            <div className="col-lg-6 my-auto">
                                <ul className="lh-lg">
                                    <li>Pengen jadi Full Stack Developer biar punya gaji tinggi?</li>
                                    <li>Udah belajar coding, tapi masih nggak bisa-bisa?</li>
                                    <li>Banyak tutorial, tapi malah jadi bingung harus ngikutin yang mana?</li>
                                    <li>Belajar sendirian, kalau error nggak ada yang bantuin?</li>
                                    <li>Mau ikut bootcamp coding tapi nggak nahan lihat harganya yang puluhan juta?</li>
                                    <li><i>Boro-boro</i> jadi Full Stack Developer, yang ada malah jadi Full Stuck!</li>
                                </ul>
                            </div>
                        </div>
                        <p className="text-center fw-bolder text-warning mt-5 mb-0">Jangan-jangan selama ini kamu salah cara belajarnya!</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionIntro;
