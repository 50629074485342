import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionJoin = () => {
    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <h2 className="section-title fw-bold text-center mb-5">Yuk Gabung di <span className="text-warning">KelasFullstack.id</span></h2>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Belajar sepuasnya, tidak ada batas waktu. Beli sekali akses seumur hidup. Materi update terus mengikuti perkembangan zaman dan tren industri, tanpa tambahan biaya.</p>
                        <ul className="lh-lg">
                            <li>1000+ Materi Video Pembelajaran</li>
                            <li>92+ Jam Durasi Belajar</li>
                            <li>45 Modul</li>
                            <li>1x Bayar, Akses + Update Materi Seumur Hidup</li>
                        </ul>
                        <a className="btn btn-outline-warning py-2 px-3 rounded-1" href="https://youtube.com/playlist?list=PLsRgyXGy0Eo5aTfRTg7uKP-xGJHoR3n0L" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon className="me-2" icon={faPlayCircle} />
                            Tonton Demo Materi
                        </a>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-dashboard-2.webp" alt="Join 1" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Alur belajar terarah disusun secara komprehensif oleh para expert.Nggak usah pusing-pusing lagi mikirin roadmap belajar, tinggal ikutin aja materinya satu persatu sampai selesai.100% teruji ampuh!</p>
                        <p className="lh-lg">Pemula, belum pernah belajar coding dan nggak punya background IT juga bisa gabung. Diajarin dari 0 banget!</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-dashboard.webp" alt="Join 2" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Error, stuck atau punya kendala saat belajar ngikutin materi? Langsung tanyain aja di Forum Tanya Jawab!</p>
                        <p className="lh-lg">Pertanyaan-pertanyaanmu akan dijawab secara sigap oleh para mentor.</p>
                        <p className="lh-lg">Forum Tanya Jawab aktif pada jam kerja.</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-forum.webp" alt="Join 3" />
                    </div>
                </div>
                <div className="row justify-content-between mb-5">
                    <div className="col-lg-6 my-auto">
                        <p className="lh-lg">Konsultasi online via Zoom seminggu sekali.</p>
                        <p className="lh-lg">Nanya, curhat, konsultasi atau ngobrol langsung dengan mentor. Kamu bukan cuma nonton video doang, tapi belajar dan dibimbing langsung oleh manusia.</p>
                    </div>
                    <div className="col-lg-6 my-auto order-first mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-zoom.webp" alt="Join 4" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionJoin;
