import { formatPrice } from "../../../utils"

const CardProgram = ({ id, name, description, thumbnail, link, normalPrice, retailPrice, borderColor }) => {
    return (
        <div className="card bg-soft-dark mb-3 rounded-3">
            <div className="row g-0">
                <div className="col-5">
                    <img height="280px" width="100%" src={thumbnail} className="rounded" alt={name} style={{ objectFit: 'cover' }} />
                </div>
                <div className="col-7 position-relative">
                    <div className="card-body text-start">
                        <h5 className="fw-bolder">{name}</h5>
                        <p className="card-text small">{description}</p>
                        <del className="text-danger">{formatPrice(normalPrice)}</del>
                        <p className="fw-bolder">{formatPrice(retailPrice)}</p>
                        <a
                            onClick={() => window.ttq.track('InitiateCheckout', {
                                content_type: 'product',
                                content_id: id,
                                value: retailPrice,
                                currency: 'IDR'
                            })}
                            className="btn btn-warning fw-bolder rounded-3"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Gabung Program
                        </a>
                    </div>
                    <div className="position-absolute top-50 end-0 translate-middle-y">
                        <span style={{ display: 'block', background: borderColor, width: '0.5em', height: '7em' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardProgram