import { Link } from "react-router-dom";
import styles from '../SectionIntro/SectionIntro.module.scss';

const SectionMateri = () => {
    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center mb-5">
                    <h2 className="section-title fw-bold mb-4">Materi <span className="text-warning">Belajar</span></h2>
                    <p>Intip rangkaian materi belajar yang akan kamu pelajari di program ini:</p>
                </div>

                <div className="row justify-content-around mb-5">
                    <div className="col-lg-6 my-auto mb-5 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-materi.webp" alt="Materi Belajar" />
                        <div className="text-center my-4">
                            <Link className="btn btn-outline-warning py-2 px-3 rounded-1" to="/roadmap">Lihat Silabus</Link>
                        </div>
                    </div>
                    <div className="col-lg-5 my-auto">
                        <div className="accordion accordion-flush" id="customAccordion">
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button bg-dark shadow-none fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                                        1. Pengenalan Pemrograman
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#customAccordion">
                                    <div className="accordion-body bg-secondary">
                                        <p className="lh-lg">Meliputi pengenalan tentang pemrograman komputer, jalur-jalur karir dalam programming, apa saja yang harus dipersiapkan untuk memulai karir sebagai programer serta pengetahuan dasar dan yang perlu kamu ketahui untuk memulai belajar pemrograman.</p>
                                        <p className="lh-lg">Kamu juga akan belajar tentang algoritma, dasar-dasar pemrograman, pengenalan tentang teks editor dan Git.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button bg-dark collapsed shadow-none fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        2. Front End Dasar
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#customAccordion">
                                    <div className="accordion-body bg-secondary">
                                        <p className="lh-lg">Kamu akan belajar tentang HTML, CSS dan JavaScript. Kamu akan mulai berlatih membuat halaman website sederhana dengan teknologi Front End tingkat dasar. Sampai sini, kamu pasti sudah akan merasakan progress hasil belajarmu. Selanjutnya kamu juga akan belajar tentang library JQuery.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button bg-dark collapsed shadow-none fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        3. Front End Lanjutan
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#customAccordion">
                                    <div className="accordion-body bg-secondary">
                                        <p className="lh-lg">Di tahap ini, kamu akan mulai belajar beragam Front End Framework yang digunakan dalam dunia industri untuk membuat aplikasi berbasis web. Kamu akan belajar tentang Bootstrap Framework, Tailwind dan Vue.js.</p>
                                        <p className="lh-lg">Kamu akan merasakan secara langsung halaman website yang kamu buat akan tampak lebih canggih, keren, responsive dan mulai terlihat seperti beragam website yang sering kamu lihat di internet. Jika kamu mempelajarinya dengan baik, kamu akan bisa membuat tampilan halaman website seperti apapun yang kamu mau.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button bg-dark collapsed shadow-none fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        4. Back End Dasar
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#customAccordion">
                                    <div className="accordion-body bg-secondary">
                                        <p className="lh-lg">Kalau pada pembahasan tentang Front End kamu lebih banyak belajar tentang tampilan depan website yang hasilnya kelihatan secara langsung. Pada tahap ini kamu akan mulai lebih banyak belajar tentang logika dan proses kerja sebuah aplikasi di balik layar.</p>
                                        <p className="lh-lg">Kamu akan belajar pemrograman PHP, dasar-dasar database, penggunaan MySQL serta konsep dasar Pemrograman Berorientasi Object (OOP).</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button bg-dark collapsed shadow-none fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        5. Back End Lanjutan
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#customAccordion">
                                    <div className="accordion-body bg-secondary">
                                        <p className="lh-lg">Pada tahap Back End tingkat lanjut, kamu akan belajar beberapa framework yang paling sering digunakan di industri, yaitu CodeIgniter dan Laravel.</p>
                                        <p className="lh-lg">Sampai di tahap ini keahlian coding yang kamu miliki untuk menghasilkan sebuah aplikasi berbasis web akan semakin baik dan sudah sesuai standar industri.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="flush-headingSix">
                                    <button className="accordion-button bg-dark collapsed shadow-none fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                        6. Studi Kasus Fullstack
                                    </button>
                                </h2>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#customAccordion">
                                    <div className="accordion-body bg-secondary">
                                        <p className="lh-lg">Setelah semua keahlian yang dibutuhkan terpenuhi, waktunya mengkombinasikannya dan mengintegrasikannya dalam sebuah project studi kasus.</p>
                                        <p className="lh-lg">Kamu akan belajar langsung dari beragam studi kasus yang telah disediakan. Pada akhirnya sehebat apapun pemahaman codingmu, kalau tidak bisa mempraktekannya dalam sebuah project nyata, maka itu akan sia-sia.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`card ${styles.card} text-white`}>
                    <div className="card-body p-lg-5 text-center">
                        <h2 className="section-title fw-bold mb-4">Update Materi Tambahan</h2>
                        <p className="lh-lg">Untuk melengkapi stack yang ada, saat ini kami sedang mengerjakan materi selanjutnya, yaitu Fullstack JavaScript. Tentu saja akan kamu bisa pelajari juga nantinya setelah materi selesai dibuat.</p>
                        <p className="lh-lg fw-bolder">NodeJs, ExpressJs, MongoDb, ReactJs, NextJs, NuxtJs, SvelteJs</p>
                        <div className="d-flex gap-2 gap-lg-3 justify-content-center">
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/node-js-logo.webp" loading="lazy" alt="Tech" />
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/expressjs-logo.webp" loading="lazy" alt="Tech" />
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/mongodb-logo.webp" loading="lazy" alt="Tech" />
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/reactjs-logo.webp" loading="lazy" alt="Tech" />
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/nextjs-logo.webp" loading="lazy" alt="Tech" />
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/nuxtjs-logo.webp" loading="lazy" alt="Tech" />
                            <img className={`img-fluid ${styles['logo-tech']}`} src="/assets/img/tech/sveltejs-logo.webp" loading="lazy" alt="Tech" />
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-lg-6">
                                <Link to="/roadmap" className="btn btn-outline-warning">Rencana Pengembangan Materi</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row g-5 my-5">
                    <div className="col-lg-6 my-auto">
                        <img className="img-fluid" src="https://image.web.id/images/Mask-group-3d02c4eb8ed791161.png" alt="Featured Course" />
                    </div>
                    <div className="col-lg-6 my-auto">
                        <h2 className="section-title fw-bold mb-4">Akses ke <span className="text-warning">54 Jam Rekaman</span> Live Class <span className="text-warning">KelasFullstack</span></h2>
                        <p className="text-white lh-lg">Kamu dapat melihat rekaman live class angkatan sebelumnya, yang didalamnya terdapat sharing pengalaman, diskusi materi dan materi belajar langsung dari mentor di KelasFullstack</p>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default SectionMateri;
