import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Promo from "./pages/Promo/Promo";
import Roadmaps from "./pages/Roadmaps/Roadmaps";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/roadmap' element={<Roadmaps />} />
                <Route path='/promo' element={<Promo />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};

export default App;