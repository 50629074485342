import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionMentors = () => {
    const mentors = [
        {
            thumbnail: '/assets/img/oriza.webp',
            name: 'Ahmad Oriza',
            experience: 'CTO dan Co-Founder CODEPOLITAN. Former IT lead programmer di Ajita (Top Digital Agency Jakarta) dengan berbagai proyek brand besar seperti Samsung, Mitsubishi, Hypernet, Holcim, LG, Djarum. Former programmer di Retinad WIFI, former programmer di Mein Creative Studio, IT Consultant di BSKAP Kemendikbudristek.',
            github: 'https://github.com/gemblue',
            linkedin: 'https://linkedin.com/ahmadoriza'
        },
        {
            thumbnail: '/assets/img/hakim.webp',
            name: 'Ahmad Hakim',
            experience: 'Software Engineer di GovTech Edu, Technical Lead di Nusabot, Former Software Engineer di Track&ROLL (HR SaaS Brunei), Web Developer di Dinas Komunikasi Informatika dan Statistik Kota Cirebon untuk Project Smart City. Juga aktif sebagai mentor dan membuat konten belajar pemrograman di CodePolitan serta YouTube Channel IDStack. "PHP is 👌, JavaScript is 💪 . PHP + JavaScript = 💰"',
            github: 'https://github.com/lunadiotic',
            linkedin: 'https://www.linkedin.com/in/aimeliala/'
        },
        {
            thumbnail: '/assets/img/img-mentor-5.webp',
            name: 'Aldi Zainafif',
            experience: 'Software Engineer di Flip. Former Senior Software Engineer di Quipper. Former Backend Software Engineer di Bukalapak. Former Information Technology Expert di Pemerintahan Kota Cimahi untuk pengembangan portal for region planning BAPPEDA (Badan pengembangan dan Penelitian Daerah). Former Website Developer di BAS-IT Studio. Berpengalaman sebagai programmer profesional sejak 2015.',
            github: 'https://github.com/zainafifaldi',
            linkedin: 'https://www.linkedin.com/in/aldizainafif/'
        },
        // {
        //     thumbnail: '/assets/img/img-mentor-6.webp',
        //     name: 'Nusendra Hanggarawan',
        //     experience: 'FullStack Developer & The Initiator of SurabayaJS. Former Full Stack Developer di Circular (YC W22). Former Software Developer di Neowave Solutions Sdn Bhd. Former Software Developer di PT. BENTENG API TECHNIC. Former Software Developer di DDTronik. Passionate software engineer dan berpengalaman sebagai developer profesional sejak 2014.',
        //     github: 'https://github.com/nusendra',
        //     linkedin: 'https://www.linkedin.com/in/nusendra-hanggarawan-79854990/'
        // },
        // {
        //     thumbnail: '/assets/img/haidar.webp',
        //     name: 'M. Haidar Hanif',
        //     experience: 'Helping people in Education, Engineering, Software, Web, UI/UX, Product, Business Educator & Mentor Software Engineer & Full Stack Web Developer. Founder Catamyst, Content Developer & Mentor CODEPOLITAN, Curriculum Contributor & Instructor RevoU. Former Co-Founder Impact Byte, Former Software Engineering Instructor Hacktiv8, Developer Relations Lead Kontenbase.',
        //     github: 'https://github.com/mhaidarhanif',
        //     linkedin: 'https://id.linkedin.com/in/mhaidarhanif'
        // },
    ];

    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold">Belajar dari Para <span className="text-warning">Expert</span></h2>
                    <p className="my-3">Dalam program ini kamu akan dibimbing oleh para mentor expert.</p>
                </div>
                <div className="row justify-content-center px-lg-4">
                    {mentors.map((mentor, index) => {
                        return (
                            <div className="col-md-6 col-xl-4 p-3" key={index}>
                                <img className="img-fluid d-block mx-auto rounded-3" src={mentor.thumbnail} alt={mentor.name} />
                                <div className="text-center my-4">
                                    <h5 className="fw-bolder mb-3">{mentor.name}</h5>
                                    <p>{mentor.experience}</p>
                                    <div className="my-3">
                                        {mentor.github ? (
                                            <a className="link mx-2" href={mentor.github} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon size="2x" icon={faGithub} />
                                            </a>
                                        ) : null}
                                        {mentor.linkedin ? (
                                            <a className="link mx-2" href={mentor.linkedin} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon size="2x" icon={faLinkedin} />
                                            </a>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionMentors;