import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import { faBriefcase, faHouseLaptop, faLaptopCode, faPersonWalking, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionBenefits = () => {
    const benefits = [
        {
            icon: faSackDollar,
            title: 'Potensi Punya Gaji Tinggi',
            content: `Fullstack Developer rata-rata digaji Rp 7jt - 12jt per bulan di Indonesia, tergantung pengalaman dan lokasi kerja. Cek di sini: <a class="link text-warning" href="https://id.indeed.com/career/full-stack-developer/salaries">Info Gaji Fullstack Developer</a>`
        },
        {
            icon: faBriefcase,
            title: 'Peluang Kerja Terbuka Lebar',
            content: `Nggak cuma kerja di Indonesia, kamu juga bisa bekerja di luar negeri baik onsite atau remote kalau punya keahlian Fullstack Developer. Cek di sini: <a class="link text-warning" href="https://remoteok.com/remote-full-stack-jobs">Remote Fullstack Developer</a>`
        },
        {
            icon: faPersonWalking,
            title: 'Bebas Pilih Role Kerja',
            content: `Kalau kamu menguasai keahlian Fullstack Developer, kamu bisa milih role kamu sendiri apakah mau ambil kerjaan sebagai Front End Developer, Back End Developer atau bahkan Fullstack Developer.`
        },
        {
            icon: faHouseLaptop,
            title: 'Cuan Melalui Project Freelance',
            content: `Ingin kerja tidak terikat? Kamu juga bisa menjadi freelancer dengan menjual jasa pembuatan website atau aplikasi berbasis web. Cek di mari: <a class="link text-warning" href="https://www.upwork.com/freelance-jobs/">upwork.com</a>, <a class="link text-warning" href="https://www.fiverr.com/">fiverr.com</a>, <a class="link text-warning" href="https://www.sribulancer.com/">sribulancer.com</a>, <a class="link text-warning" href="https://www.projects.co.id/">projects.co.id</a>`
        },
        {
            icon: faWindowRestore,
            title: 'Bisa Bikin Produk dan Bisnis Online Sendiri',
            content: `Punya ide bisnis online sendiri? Kalau kamu Fullstack Developer, kamu gak usah bingung. Langsung aja bikin sendiri! Siapa tau kamu bakal jadi the next <a class="link text-warning" href="https://id.wikipedia.org/wiki/Nadiem_Makarim">Nadiem Makarim</a>, <a class="link text-warning" href="https://id.wikipedia.org/wiki/William_Tanuwijaya">William Tanuwijaya</a>, atau <a class="link text-warning" href="https://id.wikipedia.org/wiki/Achmad_Zaky">Achmad Zaky</a>`
        },
        {
            icon: faLaptopCode,
            title: 'Skill yang Dibutuhin Industri',
            content: `Zaman selalu berubah, akan banyak pekerjaan yang akan tidak relevan lagi ke depannya. Tapi kalau kamu jadi Fullstack Developer setidaknya kamu akan tetap dibutuhkan industri. Bahkan Pak Jokowi pernah bilang, skill coding akan lebih penting dari bahasa Inggris ke depannya. Nggak percaya? Cek <a class="link text-warning" href="https://www.cnnindonesia.com/ekonomi/20211013125502-532-707177/jokowi-sebut-ilmu-coding-lebih-penting-dari-bahasa-inggris">di sini</a>`
        },
    ];

    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold">Apa Untungnya Jadi <span className="text-warning">Fullstack Developer?</span></h2>
                </div>
                <div className="card bg-dark">
                    <div className="card-body">
                        <div className="row">
                            {benefits.map((benefit, index) => {
                                return (
                                    <div className="col-lg-6 col-xl-4 p-3 mb-4" key={index}>
                                        <div className="card bg-soft-dark shadow h-100">
                                            <div className="card-body p-4">
                                                <FontAwesomeIcon size="4x" className="text-warning" icon={benefit.icon} />
                                                <h5 className="fw-bolder mt-4 mb-3">{benefit.title}</h5>
                                                <p className="lh-lg" dangerouslySetInnerHTML={{ __html: benefit.content }} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionBenefits;
