import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionFasilitators = () => {
    const facilitators = [
        {
            thumbnail: '/assets/img/facilitator-bahrul.png',
            name: 'Bahrul Rozak',
            experience: 'Technical Learning Fascilitator di CODEPOLITAN dan Professional Full Stack Engineer dengan pengalaman lebih dari 2 tahun',
            github: 'https://github.com/Bahrul-Rozak',
            linkedin: 'https://id.linkedin.com/in/bahrul-rozak'
        },
        {
            thumbnail: '/assets/img/img-mentor-4.webp',
            name: 'Muhammad Ghazariz',
            experience: 'Lead Community manager di CODEPOLITAN. Project Manager di Virtual Moves Co. Community Manager di WPU Discord Server. Front End Web Developer Freelancer. Berpengalaman sebagai programmer profesional sejak 2019.',
            github: '',
            linkedin: 'https://www.linkedin.com/in/ghazariz/'
        }
    ];
    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold">Fasilitator <span className="text-warning">Belajar</span></h2>
                    <p className="my-3">Agar mendapatkan hasil maksimal dalam belajar, kamu akan ditemani dan dibimbing oleh para expert</p>
                </div>
                <div className="row justify-content-center px-lg-4">
                    {facilitators.map((facilitator, index) => {
                        return (
                            <div className="col-md-6 col-xl-4 p-3" key={index}>
                                <img width={280} height={280} className="d-block mx-auto rounded-3" style={{ objectFit: 'cover' }} src={facilitator.thumbnail} alt={facilitator.name} />
                                <div className="text-center my-4">
                                    <h5 className="fw-bolder mb-3">{facilitator.name}</h5>
                                    <p>{facilitator.experience}</p>
                                    <div className="my-3">
                                        {facilitator.github ? (
                                            <a className="link mx-2" href={facilitator.github} target="_blank" rel="noopener noreferrer" title={facilitator.github}>
                                                <FontAwesomeIcon size="2x" icon={faGithub} />
                                            </a>
                                        ) : null}
                                        {facilitator.linkedin ? (
                                            <a className="link mx-2" href={facilitator.linkedin} target="_blank" rel="noopener noreferrer" title={facilitator.linkedin}>
                                                <FontAwesomeIcon size="2x" icon={faLinkedin} />
                                            </a>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionFasilitators;