import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionFasilitas = () => {
    return (
        <section className="bg-secondary text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-between">
                    <div className="col-lg-6 my-auto mb-5 mb-lg-auto">
                        <h2 className="section-title fw-bold text-center mb-5"><span className="text-warning">Fasilitas</span> Belajar</h2>
                        <p>Yang akan kamu dapatkan saat kamu gabung dalam program ini</p>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            Akses Materi + Update Materi Seumur Hidup
                        </div>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            1000+ Materi Video Pembelajaran
                        </div>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            Forum Tanya Jawab
                        </div>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            Konsultasi Online Mingguan
                        </div>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            Grup Member untuk Silaturahmi dan Informasi
                        </div>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            Sertifikat Digital
                        </div>
                        <div className="d-flex align-items-start mb-3">
                            <FontAwesomeIcon className="text-warning my-auto me-2" size="lg" icon={faCheckSquare} />
                            6 Project Studi Kasus
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto order-first order-lg-last mb-4 mb-lg-auto">
                        <img className="img-fluid" src="/assets/img/img-certificate.webp" alt="Fasilitas Belajar" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionFasilitas;
