import { faPodcast, faVideo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CardLesson = ({ title, duration, lesson, type, percentage, tag }) => {
    return (
        <>
            {
                type === "active" && (
                    <div className="card bg-soft-dark p-3 h-100" style={{ minHeight: '140px' }}>
                        <span className={`text-capitalize text-${tag === 'Live Class' ? 'info' : 'warning'}`}><FontAwesomeIcon icon={tag === 'Live Class' ? faPodcast : faVideo} /> {tag}</span>
                        <h5 className="fw-bold lh-lg">{title}</h5>
                        <p className="m-0 text-muted">{duration} Jam . {lesson} Materi</p>
                    </div>
                )
            }
            {
                type === "progress" && (
                    <div className="card bg-soft-dark p-3 h-100" style={{ minHeight: '140px' }}>
                        <span className={`text-capitalize text-${tag === 'Live Class' ? 'info' : 'warning'}`}><FontAwesomeIcon icon={tag === 'Live Class' ? faPodcast : faVideo} /> {tag}</span>
                        <h5 className="fw-bold lh-lg">{title}</h5>
                        <div className="progress" style={{ backgroundColor: '#36626c', height: '13px' }}>
                            <div className="progress-bar text-dark" style={{ width: `${percentage}%`, backgroundColor: '#31eaff' }} role="progressbar">{percentage}%</div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <div className="badge p-2" style={{ backgroundColor: '#36626c', color: '#31eaff' }}>On Progress</div>
                        </div>
                    </div>
                )
            }
            {
                type === "upcoming" && (
                    <div className="card p-3 h-100" style={{ backgroundColor: '#222932', minHeight: '140px' }}>
                        <span className={`text-capitalize text-${tag === 'Live Class' ? 'info' : 'warning'}`}><FontAwesomeIcon icon={tag === 'Live Class' ? faPodcast : faVideo} /> {tag}</span>
                        <h5 className="fw-bold mb-auto text-muted lh-lg">{title}</h5>
                        <div>
                            <div className="badge bg-warning text-dark">Upcoming</div>
                        </div>
                    </div>
                )
            }
            {
                type === "new" && (
                    <div className="card p-3 h-100" style={{ backgroundColor: '#222932', minHeight: '140px' }}>
                        <span className={`text-capitalize text-${tag === 'Live Class' ? 'info' : 'warning'}`}><FontAwesomeIcon icon={tag === 'Live Class' ? faPodcast : faVideo} /> {tag}</span>
                        <h5 className="fw-bold lh-lg">{title}</h5>
                        <p className="mb-auto text-muted">{duration} Jam . {lesson} Materi</p>
                        <div>
                            <div className="badge bg-success">New</div>
                        </div>
                    </div>
                )
            }
        </>
    )
};

export default CardLesson;
