import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Layout from "../../components/global/Layout/Layout";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>404 Not Found - KelasFullstack.id</title>
            </Helmet>
            <Layout>
                <section className="bg-dark text-white">
                    <div className="container p-4 p-lg-5">
                        <div className="row justify-content-center mt-5">
                            <div className="col-lg-6 text-center mt-5">
                                <FontAwesomeIcon className="text-warning mb-4" size="5x" icon={faQuestion} />
                                <h1 className="fw-bold text-white">
                                    <span className="text-warning">404</span> Not Found
                                </h1>
                                <p className="text-white">Ups, halaman yang kamu tuju tidak ditemukan. Silahkan kembali ke halaman utama.</p>

                                <Link to="/" className="btn btn-outline-warning rounded-3 btn-lg my-5">Kembali</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default NotFound;
