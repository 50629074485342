export const pengenalanPemrogramanData = [
    {
        title: "1. Mengenal Pemrograman Komputer",
        total_duration: 2,
        total_lesson: 32,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "2. Algoritma dan Pemrograman Dasar",
        total_duration: 1,
        total_lesson: 27,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "3. Mahir Menggunakan Teks Editor Buat Pemula",
        total_duration: 1,
        total_lesson: 4,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "4.Belajar GIT Dasar",
        total_duration: 1,
        total_lesson: 14,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "5. Belajar Menggunakan Terminal atau CMD untuk Development",
        total_duration: 1,
        total_lesson: 9,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "6. Personal Branding Developer",
        total_duration: 1,
        total_lesson: 5,
        type: "active",
        tag: "Live Class"
    },
    {
        title: "7. Bahasa Inggris for Developer",
        type: "active",
        total_duration: 1.38,
        total_lesson: 5,
        tag: "Live Class"
    },
    {
        title: "8. Kode Etik Developer",
        type: "upcoming",
        tag: "Online Course"
    },
];

export const frontendDasarData = [
    {
        title: "9. Belajar Dasar HTML",
        total_duration: 3,
        total_lesson: 30,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "10. Belajar Dasar CSS",
        total_duration: 3,
        total_lesson: 39,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "11. UI/UX untuk Developer",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "12. Belajar Dasar Bootstrap CSS Framework",
        total_duration: 2,
        total_lesson: 17,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "13. Membuat Landing Page Website dengan Bootstrap",
        total_duration: 2,
        total_lesson: 16,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "14. Belajar Dasar Tailwind CSS",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "15. Membuat Halaman Website Portofolio Menggunakan Tailwind CSS",
        total_duration: 2,
        total_lesson: 19,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "16. Belajar JavaScript",
        total_duration: 4,
        total_lesson: 83,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "17. Memahami Ecma Script",
        type: "upcoming",
        tag: "Online Course"
    },
];

export const frontendLanjutanData = [
    {
        title: "18. Belajar JavaScript DOM",
        total_lesson: 25,
        total_duration: 2,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "19. Belajar JavaScript Asyncronous",
        total_duration: 1,
        total_lesson: 10,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "20. Belajar AJAX dan Web API",
        total_duration: 1,
        total_lesson: 13,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "21. Belajar Konsep OOP di JavaScript",
        total_duration: 1,
        total_lesson: 6,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "22. Belajar JQuery",
        total_duration: 1,
        total_lesson: 10,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "23. Belajar AlpineJS",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "24. Pengenalan SASS pada CSS",
        total_duration: 2,
        total_lesson: 13,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "25. Belajar Cepat Vue.js",
        total_duration: 4,
        total_lesson: 52,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "26. Belajar React JS",
        total_duration: 2,
        total_lesson: 23,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "27. React.js 101: Membuat dan Menggunakan Komponen dalam Aplikasi Berbasis React.js",
        total_duration: 2,
        total_lesson: 32,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "28. React.js State Management - Panduan Menggunakan State yg Baik",
        total_duration: 1,
        total_lesson: 14,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "29. Belajar React.js dengan Membuat Aplikasi Split Bill",
        total_duration: 1,
        total_lesson: 10,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "30. React.js - Belajar Lebih Dalam Tentang Component dan Jenisnya",
        total_duration: 2,
        total_lesson: 19,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "31. React.js - Bagaimana React.js Bekerja di Balik Layar",
        total_duration: 2,
        total_lesson: 18,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "32. React.js State - Mengelola Perubahan Tampilan dengan Data bersama Event Handler",
        total_duration: 1,
        total_lesson: 19,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "33. React.js - Belajar Hook Effect dan Data Fetching",
        total_duration: 2,
        total_lesson: 20,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "34. Belajar NextJS",
        total_duration: 4,
        total_lesson: 69,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "35. Belajar NuxtJS",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "36. Belajar Svelte",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "37. Belajar Astro",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "38. Belajar Hosting di Vercel, Netlify, Firebase, etc",
        type: "new",
        tag: "Live Class"
    },
];

export const backendDasarData = [
    {
        title: "39. Belajar Pemrograman PHP",
        total_duration: 4,
        total_lesson: 46,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "40. Pengenalan Database Menggunakan MariaDB",
        total_duration: 1,
        total_lesson: 27,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "41. Penggunaan Database MySQL dan PHP",
        total_duration: 2,
        total_lesson: 16,
        type: "active",
        tag: "Online Course"
    },
];

export const backendLanjutanData = [
    {
        title: "42. Konsep Dasar Pemrograman Berorientasi Object",
        total_duration: 2,
        total_lesson: 54,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "43. Pemrograman Berorientasi Object Lanjutan",
        total_duration: 2,
        total_lesson: 27,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "44. Design Pattern",
        total_duration: 1,
        total_lesson: 13,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "45. Belajar Konsep API",
        total_duration: 1.48,
        total_lesson: 4,
        type: "active",
        tag: "Live Class"
    },
    {
        title: "46. Belajar Cara Menggunakan Tools API",
        total_duration: 1.48,
        total_lesson: 4,
        type: "active",
        tag: "Live Class"
    },
    {
        title: "47. Menguasai Framework CodeIgniter",
        total_duration: 5,
        total_lesson: 28,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "48. Menguasai Framework CodeIgniter 4 (4.4.3 2023)",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "49. Membuat Restfull API dengan CI 4",
        type: "upcoming",
        tag: "Online Course"
    },
    {
        title: "50. Belajar Framework Laravel Dasar",
        total_lesson: 56,
        total_duration: 5,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "51. Membangun Restfull API dengan Laravel",
        total_duration: 1,
        total_lesson: 19,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "52. Sistem Otentikasi API Berbasis OAuth dengan Laravel Passport",
        total_duration: 2,
        total_lesson: 21,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "53. Membuat Role Management Menggunakan Laravel",
        total_duration: 1,
        total_lesson: 12,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "54. Belajar Dasar Node.js dan NPM",
        total_duration: 1,
        total_lesson: 15,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "55. Belajar Dasar Express.js",
        total_duration: 1,
        total_lesson: 7,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "56. Belajar Membuat Halaman Web Dinamis dengan Express.js dan EJS",
        total_duration: 1,
        total_lesson: 12,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "57. Belajar RESTful dengan Express.js",
        total_duration: 1,
        total_lesson: 13,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "58. Belajar MongoDB",
        total_duration: 1,
        total_lesson: 11,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "59. Belajar Menggunakan MongoDB di JavaScript",
        total_duration: 1,
        total_lesson: 15,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "60. Belajar Membuat Project Express.js dengan MongoDB",
        total_duration: 1,
        total_lesson: 10,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "61. Implementasi Middleware pada Express.js",
        total_duration: 1,
        total_lesson: 7,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "62. Implementasi Relasi Database MongoDB di Express, Mongoose",
        total_duration: 1,
        total_lesson: 7,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "63. Manajemen Route dan Cookies di Express.js",
        total_duration: 1,
        total_lesson: 7,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "64. Manajemen Hot Data dengan Session di Express.js",
        total_duration: 1,
        total_lesson: 5,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "65. Belajar Konsep Auth dan Implementasi di Express.js",
        total_duration: 1,
        total_lesson: 12,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "66. Belajar Menguasai Nest Js",
        total_duration: 3,
        total_lesson: 75,
        type: "new",
        tag: "Online Course"
    },
    {
        title: "67. Membangun Restfull dengan Adonis",
        total_duration: 1,
        total_lesson: 21,
        type: "new",
        tag: "Online Course"
    },
];

export const studiKasusFullstackMonolithData = [
    {
        title: "68. Membangun Aplikasi Cek Ongkir Menggunakan Laravel",
        total_duration: 1,
        total_lesson: 12,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "69. Implementasi Payment Gateway Indonesia Menggunakan Laravel",
        total_duration: 1,
        total_lesson: 12,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "70. Membuat Toko Online dengan Laravel LiveWire dan Payment Gateway",
        total_duration: 3,
        total_lesson: 30,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "71. Membangun Website Toko Online Berbasis CodeIgniter",
        total_duration: 15,
        total_lesson: 103,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "72. Membangun Website Seperti KAI Access dan Traveloka, OTA",
        type: "upcoming",
        tag: "Online Course"
    },
];

export const studiKasusFullstackJamstackData = [
    {
        title: "73. Fullstack Tutorial MongoDB, Express, Vue, Node – Shopping Cart",
        total_duration: 4,
        total_lesson: 24,
        type: "active",
        tag: "Online Course"
    },
    {
        title: "74. Belajar Vue 3 dan Firebase Studi Kasus Blog",
        total_duration: 3,
        total_lesson: 31,
        type: "active",
        tag: "Online Course"
    },
];