import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionFaq = () => {
    const faqs = [
        {
            id: 1,
            question: 'Apakah belajarnya online?',
            answer: 'Ya, belajarnya online'
        },
        {
            id: 2,
            question: 'Apakah ada belajar offline-nya?',
            answer: 'Tidak, semuanya dilakukan secara online'
        },
        {
            id: 3,
            question: 'Apakah ada waktu-waktu tertentu untuk belajarnya?',
            answer: 'Tidak, untuk belajar tidak dibatasi waktu. Kamu bisa belajar sesuai dengan waktu luang yang kamu miliki. Tidak ada jam-jam tertentu untuk belajar. Kamu bisa belajar kapan saja dan di mana saja.'
        },
        {
            id: 4,
            question: 'Bagaimana cara belajarnya?',
            answer: 'Kamu bisa belajar dengan menonton video pembelajaran yang telah disediakan, kemudian mempraktekannya secara mandiri.'
        },
        {
            id: 5,
            question: 'Apakah ada pertemuan live secara online dengan mentor?',
            answer: 'Ada, namun hanya untuk sesi QnA saja, bukan untuk menyampaikan materi belajar. Semua materi belajar sudah disajikan dalam bentuk video.'
        },
        {
            id: 6,
            question: 'Apakah saya bisa mendownload semua materi belajarnya?',
            answer: 'Tidak bisa.'
        },
        {
            id: 7,
            question: 'Apakah program ini ada batas waktu belajarnya?',
            answer: 'Tidak, kamu bisa akses semua materi belajar selamanya, selama kami masih beroperasi.'
        },
        {
            id: 8,
            question: 'Apakah sewaktu-waktu bisa ada materi baru?',
            answer: 'Ya, kami selalu memperbaharui dan menambahkan materi belajarnya.'
        },
        {
            id: 9,
            question: 'Bagaimana jika saat belajar saya mengalami kesulitan?',
            answer: 'Kamu bisa menanyakannya melalui forum tanya jawab yang telah kami sediakan.'
        },
        {
            id: 10,
            question: 'Apakah bisa bertanya secara langsung pada mentor secara live?',
            answer: 'Ya, bisa. Untuk bertanya langsung pada mentor secara live, silahkan bergabung dalam program QnA mingguan.'
        },
        {
            id: 11,
            question: 'Apakah ada penyaluran kerja?',
            answer: 'Tidak. Namun kamu akan bergabung dalam grup diskusi member dan sewaktu-waktu bisa diinformasikan jika ada informasi lowongan kerja atau project.'
        },
        {
            id: 12,
            question: 'Melalui apa saya akan belajarnya?',
            answer: 'Setelah bergabung kamu akan memiliki akses halaman dashboard belajar. Kamu akan belajar melalui halaman dashboard belajar tersebut.'
        },
        {
            id: 13,
            question: 'Apakah saya bisa mendownload source code dari materi belajar?',
            answer: 'Ya, kamu bisa mendownload source code yang disediakan dalam setiap modul belajar.'
        },
        {
            id: 14,
            question: 'Apakah ada grup khusus untuk silaturahmi member?',
            answer: 'Ya, ada. Kamu akan bergabung dalam grup diskusi member.'
        },
        {
            id: 15,
            question: 'Bolehkah saya bertanya terkait hal teknis kesulitan dalam belajar di grup diskusi member?',
            answer: 'Sangat tidak dianjurkan. Untuk pertanyaan seputar materi silahkan ditanyakan di forum tanya jawab yang disediakan pada halaman dashboard user. Grup diskusi member digunakan untuk silaturahmi dan berbagi informasi.'
        },
        {
            id: 16,
            question: 'Apakah ada cara pembayaran cicilan?',
            answer: 'Untuk saat ini tidak ada.'
        },
        {
            id: 17,
            question: 'Bagaimana cara bergabung dengan program ini?',
            answer: 'Silahkan baca terlebih dahulu semua penjelasan tentang program, kemudian klik tombol warna kuning dengan tulisan Gabung Program. Lalu ikuti langkah-langkahnya.'
        }
    ];

    return (
        <section className="bg-dark text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold">Pertanyaan yang <span className="text-warning">Sering Ditanyakan</span></h2>
                    <p className="lh-lg">Simak beberapa pertanyaan-pertanyaan berikut, siapa tahu salah satunya adalah pertanyaan yang ingin kamu tanyakan.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="accordion accordion-flush" id="customAccordion">
                            {faqs.map((faq) => {
                                return (
                                    <div className="accordion-item border-0" key={faq.id}>
                                        <h2 className="accordion-header" id={`flush-heading${faq.id}`}>
                                            <button className={`accordion-button ${faq.id !== 1 ? 'collapsed' : ''} bg-secondary shadow-none fw-bolder`} type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${faq.id}`} aria-expanded={faq.id === 1 ? "true" : "false"} aria-controls={`flush-collapse${faq.id}`}>
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div id={`flush-collapse${faq.id}`} className={`accordion-collapse collapse ${faq.id === 1 ? 'show' : ''}`} aria-labelledby={`flush-heading${faq.id}`} data-bs-parent="#customAccordion">
                                            <div className="accordion-body bg-soft-dark">
                                                <p className="lh-lg">{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="text-center my-5">
                    <p className="lh-lg">Punya pertanyaan lain?</p>
                    <a className="btn btn-outline-warning py-2 px-4" href="https://wa.me/6285175484822?text=Hai%20Kelasfullstack%2C%20Saya%20ingin%20bergabung%20dengan%20program%20kelasfullstack%2C%20bisa%20tolong%20dibantu%3F" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                        Tanya CS
                    </a>
                </div>
                <div className="row justify-content-center my-5">
                    <div className="col-auto my-auto">
                        <span>Organized by</span>
                    </div>
                    <div className="col-auto my-auto">
                        <img height="40" src="/assets/img/img-codepolitan-logo.webp" alt="Codepolitan" />
                    </div>
                </div>
                {/* <div className="row justify-content-center my-5">
                    <h5 className="text-center mb-4">Media Partner</h5>
                    <div className="col-auto my-auto">
                        <a className="link" href="https://www.petanikode.com" target="_blank" rel="noopener noreferrer">
                            <img className="rounded-circle" height="80" src="/assets/img/petanikode.webp" title="Petani Kode" alt="Petani Kode" />
                        </a>
                    </div>
                </div> */}
            </div>
        </section>
    );
};

export default SectionFaq;
