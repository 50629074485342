const SectionRoadmap = ({ title, subtitle, children }) => {
    return (
        <section className="bg-dark pt-5">
            <div className="container py-5 px-4 px-lg-5">
                <h2 className="fw-bold">{title}</h2>
                <p className="lead">{subtitle}</p>
                <div className="rounded bg-secondary py-4 px-lg-5 px-3 mt-5">
                    {children}
                </div>
            </div>
        </section>
    );
};

export default SectionRoadmap;
