import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionBenefits from '../../components/home/SectionBenefits/SectionBenefits';
import Sectioncomparison from '../../components/home/SectionComparison/Sectioncomparison';
import SectionExperts from '../../components/home/SectionExperts/SectionExperts';
import SectionFaq from '../../components/home/SectionFaq/SectionFaq';
import SectionFasilitas from '../../components/home/SectionFasilitas/SectionFasilitas';
import SectionFasilitators from '../../components/home/SectionFasilitators/SectionFasilitators';
import SectionIntro from '../../components/home/SectionIntro/SectionIntro';
import SectionInvest from '../../components/home/SectionInvest/SectionInvest';
import SectionJoin from '../../components/home/SectionJoin/SectionJoin';
import SectionMateri from '../../components/home/SectionMateri/SectionMateri';
import SectionMentors from '../../components/home/SectionMentors/SectionMentors';
import SectionProjects from '../../components/home/SectionProjects/SectionProjects';
import SectionTestimonials from '../../components/home/SectionTestimonials/SectionTestimonials';

const Promo = () => {
    return (
        <>
            <Helmet>
                <title>Kelas Online Fullstack Developer From A to Z - KelasFullstack.id</title>
            </Helmet>
            <Layout>
                <Hero />
                <SectionIntro />
                <SectionJoin />
                <SectionFasilitas />
                <SectionMateri />
                <SectionProjects />
                <SectionBenefits />
                <Sectioncomparison />
                <SectionMentors />
                <SectionFasilitators />
                <SectionExperts />
                <SectionTestimonials />
                <SectionInvest promoLink />
                <SectionFaq />
            </Layout>
        </>
    );
};

export default Promo;
