import { faClock, faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionProjects = () => {
    const projects = [
        {
            thumbnail: 'https://image.web.id/images/imagec3f4217293dd017b.png',
            name: 'Membangun Website Toko Online Berbasis CodeIgniter',
            totalModule: 103,
            totalTime: 15
        },
        {
            thumbnail: 'https://image.web.id/images/image19cd570380d3333b.png',
            name: 'Belajar Vue 3 dan Firebase Studi Kasus Blog',
            totalModule: 31,
            totalTime: 3
        },
        {
            thumbnail: 'https://image.web.id/images/imagef3cf5a18b68619e1.png',
            name: 'Implementasi Payment Gateway Indonesia Menggunakan Laravel',
            totalModule: 12,
            totalTime: 1
        },
        {
            thumbnail: 'https://image.web.id/images/imagef52a7da8b7272124.png',
            name: 'Membangun Aplikasi Cek Ongkir Menggunakan Laravel',
            totalModule: 13,
            totalTime: 1
        },
        {
            thumbnail: 'https://image.web.id/images/imaged4f32b7477f259dc.png',
            name: 'Membuat Toko Online dengan Laravel LiveWire dan Payment Gateway',
            totalModule: 30,
            totalTime: 3
        },
        {
            thumbnail: 'https://image.web.id/images/image21821ba7aaf6bed0.png',
            name: 'Fullstack Tutorial MongoDB, Express, Vue, Node - Shopping Cart',
            totalModule: 24,
            totalTime: 4
        }
    ];

    return (
        <section className="bg-secondary text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center mb-5">
                    <h2 className="section-title fw-bold">Project <span className="text-warning">Belajar</span></h2>
                    <p className="lh-lg">Setelah menyelesaikan semua materi belajar di program ini, kamu akan bisa membuat project:</p>
                </div>
                <div className="row g-5">
                    {projects.map((project, index) => {
                        return (
                            <div className="col-md-6 col-lg-4" key={index}>
                                <img className="img-fluid" src={project.thumbnail} alt={project.name} />
                                <div className="mt-4">
                                    <h5 className="fw-bold">{project.name}</h5>
                                    <hr style={{ width: '100px', height: '3px', background: 'white', border: 'none', borderRadius: '5px', opacity: 1 }} />
                                    <div className="d-flex gap-3 small">
                                        <p><FontAwesomeIcon icon={faSwatchbook} /> {project.totalModule} Module</p>
                                        <p><FontAwesomeIcon icon={faClock} /> {project.totalTime} Jam</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionProjects;
