import CardProgram from "../../global/CardProgram/CardProgram";

const SectionInvest = ({ promoLink }) => {
    return (
        <section id="invest" className="bg-secondary text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="section-title fw-bold text-center mb-5"><span className="text-warning">Investasi</span> 1x Seumur Hidup</h2>
                        <p className="fw-bolder">Perlu kamu tahu, bahwa:</p>
                        <ul className="lh-lg text-start">
                            <li>Materi belajar dalam program ini biasa dibawakan dalam program coding bootcamp dengan harga Rp 30juta sampai Rp 40juta.</li>
                            <li>Setiap 1 modul belajar dalam program ini biasa kami jual dalam training offline senilai Rp 3-4 juta. Dan di program ini kamu nggak cuma dapat 1 modul, melainkan 20 modul.</li>
                            <li>Jika kamu menyewa 1 orang mentor untuk membantu kamu belajar dan menjawab semua pertanyaanmu, kamu harus mengeluarkan biaya senilai Rp 500ribu – Rp 4juta per bulan, tergantung pengalaman mentor.</li>
                        </ul>
                        <p className="text-warning fw-bolder fs-4">Jadi, program ini sesungguhnya bernilai puluhan juta rupiah.</p>
                        <p className="lh-lg">Tapi kamu tidak perlu berinvestasi sebesar itu jika mengikuti program ini. Kamu hanya perlu berinvestasi 1x seumur hidup senilai:</p>
                    </div>
                </div>
                <div className="row justify-content-center my-4 gap-3">
                    <div className="col-lg-5">
                        <CardProgram
                            id={690}
                            borderColor="#87ceeb"
                            thumbnail="https://image.web.id/images/23213.png"
                            name="Frontend ReactJS - NextJS"
                            description="Belajar coding frontend web development dari 0 sampai mahir dengan teknologi React.js"
                            normalPrice={1400000}
                            retailPrice={560000}
                            link="https://pay.codepolitan.com/?slug=roadmap-frontend-reactjs&coupon=REACTJS"
                        />
                    </div>
                    <div className="col-lg-5">
                        <CardProgram
                            id={694}
                            borderColor="#90ee90"
                            thumbnail="https://image.web.id/images/Group-436623.png"
                            name="Frontend VueJS - NuxtJS"
                            description="Belajar coding frontend web development dari 0 sampai mahir dengan teknologi Vue.js"
                            normalPrice={1400000}
                            retailPrice={560000}
                            link="https://pay.codepolitan.com/?slug=roadmap-frontend-vuejs&coupon=VUEJS"
                        />
                    </div>
                    <div className="col-lg-5">
                        <CardProgram
                            id={693}
                            borderColor="#ffffff"
                            thumbnail="https://image.web.id/images/Group-4370232.png"
                            name="Backend NodeJS - ExpressJS"
                            description="Belajar coding Backend web development dari 0 sampai mahir dengan teknologi Node.js"
                            normalPrice={1400000}
                            retailPrice={560000}
                            link="https://pay.codepolitan.com/?slug=roadmap-backend-expressjs&coupon=EXPRESSJS"
                        />
                    </div>
                    <div className="col-lg-5">
                        <CardProgram
                            id={695}
                            borderColor="#ff4500"
                            thumbnail="https://image.web.id/images/Group-4355.png"
                            name="Backend PHP - Laravel"
                            description="Belajar coding Backend web development dari 0 sampai mahir dengan teknologi PHP"
                            normalPrice={1400000}
                            retailPrice={560000}
                            link="https://pay.codepolitan.com/?slug=roadmap-backend-laravel&coupon=LARAVEL"
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <div className="card bg-soft-dark text-white my-5" style={{ borderRadius: '20px' }}>
                            <div className="card-header bg-warning" style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                <h2 className="text-dark fw-bold">Paket Super Hemat</h2>
                            </div>
                            <div className="card-body">
                                <img className="img-fluid mb-4" src="https://image.web.id/images/Mask-group-29a7b5553921cbd1b.png" alt="" />
                                <del className="text-danger h4">Rp 2.200.000</del>
                                <p className="fw-bold h1 text-success" style={{ fontSize: '3em' }}>Rp 880.000</p>
                                <p className="fw-bolder mt-3">Bundling semua kelas</p>
                                <a
                                    onClick={() => window.ttq.track('InitiateCheckout', {
                                        content_type: 'product',
                                        content_id: 346,
                                        value: 880000,
                                        currency: 'IDR'
                                    })}
                                    className="btn btn-warning fw-bolder btn-lg rounded-1 px-3 py-2 mb-3"
                                    href={promoLink ? "https://apps.codepolitan.com/payment/cart/add/kelasfullstackid" : "https://pay.codepolitan.com/?slug=program-fullstack-web-development-lifetime&coupon=KELASFULLSTACK"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Gabung Program
                                </a>
                                <p className="fw-bolder">Kamu akan mendapatkan semua materi kelas dari semua roadmap lengkap dari program KelasFullstack ini dengan harga yang super hemat!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="text-center">
                    <small><span className="text-warning">Catatan:</span> Harga akan naik ke depannya seiring bertambahnya materi belajar. Gabung sekarang untuk lebih hemat!</small>
                </p>
            </div>
        </section>
    );
};

export default SectionInvest;
