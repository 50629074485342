const SectionExperts = () => {
    const experts = [
        {
            thumbnail: '/assets/img/img-shandika.webp',
            name: 'Sandhika Galih',
            occupation: 'Dosen di Universitas Pasundan Bandung',
            channelName: 'Web Programming UNPAS',
            channelLink: 'https://www.youtube.com/@sandhikagalihWPU',
            videoCode: '33nGS4mfFhY',
        },
        {
            thumbnail: '/assets/img/img-kelasterbuka.webp',
            name: 'Faqihza Mukhlish',
            occupation: 'Dosen di Institut Teknologi Bandung',
            channelName: 'Kelas Terbuka',
            channelLink: 'https://www.youtube.com/@KelasTerbuka',
            videoCode: 'd1eTifyeN2M',
        },
        {
            thumbnail: '/assets/img/img-dea.webp',
            name: 'Dea Afrizal',
            occupation: 'Freelancer Programmer',
            channelName: 'Dea Afrizal',
            channelLink: 'https://www.youtube.com/@deaafrizal',
            videoCode: 'N81ZSYLXQBg',
        },
    ];

    return (
        <section className="bg-secondary text-white">
            <div className="container py-5 px-4 px-lg-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold">Kata Para Expert Tentang <span className="text-warning">KelasFullstack.id</span></h2>
                    <p className="mt-4">Simak apa kata para expert tentang KelasFullstack:</p>
                </div>
                <div className="row justify-content-center">
                    {experts.map((expert, index) => {
                        return (
                            <div className="col-md-6 col-xl-4 p-3" key={index}>
                                <div className="ratio ratio-16x9">
                                    <iframe src={`https://www.youtube.com/embed/${expert.videoCode}?rel=0`} title="YouTube video" loading="lazy" allowFullScreen />
                                </div>
                                <div className="text-center my-4">
                                    <h5 className="fw-bolder">{expert.name}</h5>
                                    <p>{expert.occupation}</p>
                                    <a className="link" href={expert.channelLink} target="_blank" rel="noopener noreferrer" title={expert.channelName}>
                                        <img height="45" className="rounded-circle border border-warning border-3" src={expert.thumbnail} alt="Shandika Galih" />
                                        <span className="ms-4 text-warning">{expert.channelName}</span>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionExperts;
