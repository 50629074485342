const SectionComparison = () => {
    const tableContents = [
        {
            title: 'Cara Belajar',
            alone: 'Meraba-raba sendiri, dari 1 website ke website lain, dari 1 platform ke platform lain.',
            otherCourse: 'Belajar sesuai roadmap namun jangka waktu akses belajar yang terbatas (tidak lifetime) atau materi yang tidak lengkap (masih dasar dan menengah)',
            kelasFullstack: 'Mengikuti alur belajar terarah dari A-Z, dari Zero to Hero yang disusun oleh para expert.'
        },
        {
            title: 'Media Belajar',
            alone: 'Seadanya yang ditemukan di internet.',
            otherCourse: 'Akses materi belajar yang terbatas (tidak lifetime) serta tidak dapat pembaharuan materi',
            kelasFullstack: '60+ jam durasi belajar yang terdiri dari 20+ Modul. Materi terus diperbaharui sesuai dengan peningkatan versi teknologi'
        },
        {
            title: 'Jika ada kesulitan dalam belajar',
            alone: 'Cari solusi sendiri di internet.',
            otherCourse: 'Tidak interaktif karena tidak ada jalur komunikasi dengan para mentor dan juga peserta yang lain',
            kelasFullstack: 'Tinggal tanya di forum tanya jawab atau ditanyakan saat konsultasi online mingguan via Zoom'
        },
        {
            title: 'Mentor',
            alone: 'Tidak ada mentor yang terikat. Hanya menunggu konten kreator atau penulis blog yang berbaik hati untuk sharing. Dan mereka tidak punya tanggung jawab apapun dari konten yang dibagikan.',
            otherCourse: 'Tidak ada mentor yang mendampingi secara langsung. Mentor biasanya hanya sebagai tutor di video saja',
            kelasFullstack: 'Ada mentor-mentor yang bertanggung jawab terhadap materi belajar. Para mentor akan membantu jika peserta mengalami kesulitan dalam belajar melalui forum tanya jawab dan konsultasi online mingguan.'
        },
        {
            title: 'Harga',
            alone: 'Gratis. Modal kuota internet aja udah bisa.',
            otherCourse: 'Umumnya berbayar sesuai dengan kualitas materi yang disajikan.',
            kelasFullstack: 'Ada harga yang harus dibayarkan. Jalan kaki dari Jakarta ke Jogja juga gratis kok, tapi capek. Kalau mau nggak capek, bahkan bisa sambil tidur, bisa naik kereta api atau pesawat, tapi harus bayar.'
        },
        {
            title: 'Sertifikat',
            alone: 'Ya nggak ada',
            otherCourse: 'Sertifikat hanya ada satu ketika selesai mengikuti proses keseluruhan rangkaian kelas',
            kelasFullstack: 'Ada sertifikat digital setiap kali menyelesaikan 1 modul belajar.'
        },
        {
            title: 'Latihan',
            alone: 'Latihan sendirian. Siapa yang review hasil latihannya? Bisa minta bantuan teman. Usahakan cari teman yang lebih jago ya kalau mau minta tolong bantu review latihan.',
            otherCourse: 'Tidak ada feedback yang diberikan kepada para peserta, sehingga tidak bisa mengukur kemampuan diri sendiri',
            kelasFullstack: 'Ada tugas evaluasi mengerjakan proyek membangun aplikasi berbasis web. Tugas akan direview langsung oleh para mentor.'
        },
    ];

    return (
        <section className="bg-secondary text-white">
            <div className="container p-5">
                <div className="text-center my-5">
                    <h2 className="section-title fw-bold mb-3">Belajar Sendiri VS <span className="text-warning">KelasFullstack.id</span></h2>
                    <p><i>“Belajar coding sendiri secara gratis di internet aja banyak, ngapain repot-repot bayar?”</i>. Yakin? Coba cek ini.</p>
                </div>

                <div className="overflow-auto">
                    <table className="table table-borderless text-white" style={{ minWidth: '1000px' }}>
                        <thead className="text-center">
                            <tr>
                                <th scope="col" className="bg-dark p-3 text-start" style={{ width: '250px' }}>
                                    <h5 className="fw-bold">Fasilitas Belajar</h5>
                                    <hr className="border-top border-white border-3 opacity-100 w-25"></hr>
                                </th>
                                <th scope="col" className="bg-soft-dark p-3" style={{ width: '250px' }}>
                                    <h5 className="fw-bold">Belajar Sendirian</h5>
                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto"></hr>
                                </th>
                                <th scope="col" className="bg-soft-dark p-3" style={{ width: '250px' }}>
                                    <h5 className="fw-bold">Belajar di Tempat Lain</h5>
                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto"></hr>
                                </th>
                                <th scope="col" className="border border-2 border-warning border-bottom-0 p-3" style={{ width: '250px' }}>
                                    <img height="30px" src="/assets/img/fullstack-logo.webp" alt="Kelas Fullstack" />
                                    <hr className="border-top border-white border-3 opacity-100 w-25 mx-auto"></hr>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContents?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row" className="bg-dark p-3">{item.title}</th>
                                        <td className="bg-soft-dark p-3">{item.alone}</td>
                                        <td className="bg-soft-dark p-3">{item.otherCourse}</td>
                                        <td className={`border border-2 border-warning border-top-0 p-3 ${index !== tableContents.length - 1 && 'border-bottom-0'}`}>{item.kelasFullstack}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default SectionComparison;